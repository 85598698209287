.menu-tbv {
  width: 600px;
}

.ac-tbv {
  .camera {
    width: 30% !important;
  }
}

#trullotdb {
  background-image: url("../images/tdb\ orizzontale/Trullo/2.jpg");
  background-position: center;
  background-size: cover;
}

#trulletto {
  background-image: url("../images/tdb\ orizzontale/Trulletto/2.jpg");
  background-position: center;
  background-size: cover;
}

#girasole {
  background-image: url("../images/tdb\ orizzontale/Girasole/2.jpg");
  background-position: center;
  background-size: cover;
}

.place-card {
  width: 600px;
  display: flex;
  flex-direction: column;
  margin: 0px 30px;
}

.place-card img {
  object-fit: cover;
  height: 200px;
}

.description {
  background-color: #364731;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  color: rgb(255, 243, 226);
}

.description h2 {
  width: 90%;
  font-size: 1.5em;
  margin: 0px;
  margin-top: 25px;
  margin-bottom: 10px;
}

.description p {
  width: 90%;
  margin: 0px;
  font-size: 1em;
}

.ristoranti {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
  width: 80%;
  flex-wrap: wrap;
}

.ristoranti .chip {
  padding: 5px 10px;
  font-size: 0.8em !important;
  color: #364731;
  background-color: rgb(255, 243, 226);
  border-radius: 20px;
}

@media screen and (max-width: 900px){
  .place-card {
    width: 85vw;
  }

  .description {
    height: fit-content !important;
    padding-bottom: 30px;  
    
    p,h2{
      width: 85% !important;
    }
  }
}
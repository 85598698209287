.tbvPage {
  .miniVetrina {
    width: 100%;
    height: 60vh;
  }

  .miniVetrina img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .headerSection {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;

    h1 {
      font-family: "Dancing Script";
      font-size: 4rem;
      margin: 0px;
    }

    p {
      width: 60%;
      font-size: 1.2rem;
      font-weight: 300;
      text-align: center;
      margin: 0px;
    }
  }

  .menu {
    width: 800px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #364731;
    color: white;

    span {
      display: flex;
      width: 33%;
      padding: 15px;
      justify-content: center;
      cursor: pointer;
      transition: 0.5s ease-in-out;
    }
  }

  .menu-tbv {
    width: 600px;

    span {
      width: 50%;
    }
  }

  .camere {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;

    h1 {
      font-family: Bodoni Moda;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 3rem;
      margin: 0px;
    }

    p {
      width: 60%;
      font-size: 1.2rem;
      text-align: center;
      font-weight: 300;
      margin: 0px;
    }

    .camere-list {
      display: flex;
      width: 100%;
      justify-content: center;
      gap: 50px;
    }

    .camera {
      width: 30%;
      height: 50vh;
      transition: 0.2s ease-in-out;

      .descrizione {
        transition: 0.2s ease-in-out;
        width: 100%;
        height: 100%;
        margin: 0px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: end;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.835) 0%,
          rgba(255, 255, 255, 0) 50%
        );

        h1,
        h2,
        p {
          margin: 0px;
          padding: 0px 20px;
          color: white;
          font-family: "Lexend";
        }

        h1 {
          font-size: 1.5rem;
          font-weight: 600;
        }

        h2 {
          font-weight: 400;
          font-size: 0.9rem;
          padding-bottom: 20px;
        }

        i {
          position: absolute;
          bottom: 30px;
          right: 20px;
          font-size: 1rem;
          padding: 5px 15px;
          border-radius: 30px;
          color: #364731;
          background-color: white;
        }

        i:hover {
          color: white;
          background-color: #364731;
          cursor: pointer;
        }
      }

      .descrizione:hover {
        transition: 0.2s ease-in-out;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.835) 0%,
          rgba(0, 0, 0, 0.2) 50%
        );
      }
    }

    .camera:hover {
      transform: scale(1.03);
      transition: 0.2s ease-in-out;
    }

    #trullo {
      background-image: url("../images/tbv\ orizzontale/Trullo/1.jpg");
      background-position: center;

      background-size: cover;
    }

    #pietra {
      background-image: url("../images/tbv\ orizzontale/Pietra\ viva/1.jpg");
      background-position: center;
      background-size: cover;
    }

    #magnolia {
      background-image: url("../images/tbv\ orizzontale/Magnolia/2.jpg");
      background-position: center;
      background-size: cover;
    }
  }

  .areeComuni {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 50px;

    h1 {
      font-family: Bodoni Moda;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 3rem;
      margin: 0px;
    }

    p {
      width: 60%;
      font-size: 1.2rem;
      text-align: center;
      font-weight: 300;
      margin: 0px;
    }

    .listaAree {
      margin: 0px !important;
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;

      .camera {
        width: 22%;
        background-size: cover !important;
        background-position: center;

        .descrizione {
          transition: 0.2s ease-in-out;
          width: 100%;
          height: 100%;
          margin: 0px;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: end;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.9) 0%,
            rgba(255, 255, 255, 0) 35%
          );
        }
      }
    }
  }

  .prodotti {
    display: flex;
    flex-direction: column;
    gap: 50px;
    margin-bottom: 20px;

    h1 {
      font-family: Bodoni Moda;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 3rem;
      margin: 0px;
      text-align: center;
    }

    .listaProdotti {
      display: flex;

      .prodotto {
        margin: 0px 20px;
        flex-basis: 20%;
        height: 400px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        background-color: rgb(255, 243, 226);

        img {
          width: 100%;
          height: 200px !important;
          object-fit: cover;
        }

        .descrizione {
          padding: 10px;
          display: flex;
          flex-direction: column;
          gap: 5px;
          height: 200px;

          h1 {
            color: #364731;
            text-align: left;
            font-family: "Lexend";
            font-size: 1.5rem;
            font-weight: 700;
            margin: 0px;
            padding: 0px 10px;
          }

          h2 {
            font-family: "Lexend";
            font-size: 1rem;
            font-weight: 400;
            margin: 0px;
            padding: 0px 10px;
          }
        }
      }
    }

    button {
      display: block;
    }
  }
}

@media screen and (max-width: 900px) {
  .pageContainer {
    margin-top: 25px !important;
  }

  .headerSection {
    margin-top: 0px !important;

    h1 {
      font-size: 3rem !important;
      text-align: center;
      width: 100%;
    }

    p {
      width: 100% !important;
    }
  }

  .menu {
    display: none !important;
  }

  .camere {
    gap: 20px !important;
    h1 {
      font-size: 2.5rem !important;
    }

    p {
      width: 100% !important;
    }

    .camere-list {
      flex-direction: column !important;
      gap: 20px !important;

      .camera {
        width: 100%;

        .descrizione h1 {
          font-size: 1.5rem !important;
        }

        .descrizione h2 {
          width: 70% !important;
        }
      }
    }
  }

  .areeComuni {
    gap: 20px !important;

    h1 {
      font-size: 2.5rem !important;
    }

    .listaAree {
      flex-direction: column !important;
      gap: 20px !important;

      .camera {
        width: 100% !important;
        height: 30vh;

        h1 {
          font-size: 1.5rem !important;
        }
      }
    }
  }

  .prodotti {
    gap: 10px !important;
    h1 {
      font-size: 2.5rem !important;
    }

    .listaProdotti {
      .prodotto {
        height: fit-content !important;
        flex-basis: 100% !important;

        .descrizione {
          h1 {
            font-size: 1.5rem !important;
          }
        }
      }
    }
  }
}

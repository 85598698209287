@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Dancing+Script:wght@700&family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap");

.homePage,
.tbvPage {
  font-family: "Lexend", sans-serif;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.vetrina {
  height: 100vh;
  background-image: url("../images/tbv\ orizzontale/Trullo/1.jpg");
  background-position: 0% 20%;
  background-size: cover;
}

.MuiAppBar-root {
  box-shadow: none !important;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: #364731;
}

.MuiAppBar-root .sideSpans,
.MuiAppBar-root .logoSpan {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.MuiAppBar-root .logoSpan h1 {
  color: white;
  text-align: center;
  font-size: 3rem;
  font-family: "Dancing Script";
  margin: 20px 0px;
}

.MuiAppBar-root .sideSpans span {
  margin: 0px 10%;
  color: white;
  font-weight: 300;
  transform: translateY(5px);
  padding: 10px 0px;
}

.MuiAppBar-root .sideSpans span:hover {
  border-bottom: 1px solid white;
  cursor: pointer;
}

.MuiAppBar-root .sideSpans #prenota {
  padding: 10px 15px;
  border: 1px solid white;
  transform: translate(-10px);
  transition: 0.2s linear;
}

.MuiAppBar-root .sideSpans #prenota:hover {
  background-color: white;
  color: #282424;
  transition: 0.2s linear;
}

.mobile-toolbar {
  display: none !important;
}

.mobile-menu{
  display: none!important;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;

  span{
    padding: 20px 0px;
    text-align: center;
  }

  :last-child{
    color: white;
    background-color: #364731;
  }

  :last-child:hover{
    background-color: #485f41;
    color: white;
  }

  span:hover{
    background-color: #f5f5f5;
    cursor: pointer;
  }
}

.subtitleSection {
  margin: 5% 0%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.subtitleSection h2 {
  font-family: "Bodoni Moda";
  font-size: 3rem;
  text-transform: uppercase;
  font-weight: 400;
  margin: 0px;
}

.subtitleSection p {
  width: 65%;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 300;
}

.struttura {
  display: flex;
  width: 100%;
}

.strutture-left-section {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.strutture-left-section h1 {
  font-size: 3rem;
  font-family: "Bodoni Moda";
  font-weight: 400;
  width: 75%;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.strutture-left-section p {
  font-size: 1.2rem;
  font-weight: 300;
  width: 75%;
  line-height: 1.6;
}

.strutture-left-section .buttonSection {
  margin-top: 5%;
  width: 75%;
}

.strutture-left-section button {
  border-radius: 0px;
  border: 1.2px solid;
}

.strutture-right-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.strutture-right-section img {
  display: block;
  height: 500px;
  width: 100%;
  object-fit: cover;
}

.camereSection {
  display: flex;
  flex-direction: column;
  width: 99.5vw;
  height: 80vh;
  transform: translateX(-50px);
  margin: 5% 0px;
  background-color: #282424;
  padding-bottom: 3%;
}

.camereSection h1 {
  width: 100%;
  text-align: center;
  font-size: 3em;
  font-family: "Bodoni Moda";
  color: white;
  text-transform: uppercase;
  font-weight: 300;
}

.tbvCards {
  margin-top: 5%;
  display: flex;
  height: 60vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 3%;
}

.tbvCards .card {
  width: 30%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: 0.5s linear;
  user-select: none;
}

.tbvCards #card1 {
  background-image: url("../images/tbv\ orizzontale/Magnolia/4.jpg");
}

.tbvCards #card2 {
  background-image: url("../images/tbv\ orizzontale/orizzontale.jpg");
}

.tbvCards #card3 {
  background-image: url("../images/tbv\ orizzontale/Pietra\ viva/12.jpg");
}

.tbvCards .card .descrizione {
  width: 100%;
  height: 100%;
  margin: 0px;
  gap: 5px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.735) 0%,
    rgba(255, 255, 255, 0) 50%
  );
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: start;
  transition: 0.2s linear;
}

.tbvCards .card .descrizione h1 {
  color: white;
  font-size: 1.7em;
  margin: 25px;
}

.tbvCards .card .descrizione p {
  width: 85%;
  color: white;
  padding-left: 25px;
  padding-bottom: 20px;
  margin-top: -30px;
  display: none;
}

.tbvCards .card:hover {
  transform: translateY(-3%);
  transition: 0.2s linear;
  cursor: pointer;
}

.tbvCards .card .descrizione:hover {
  background-color: #0000006d;
  transition: 0.2s linear;
}

.divisore {
  margin-bottom: 5%;
  width: 99.5vw;
  height: 120vh;
  background-color: rgb(255, 243, 226);
  transform: translateX(-50px);
  display: flex;
  gap: 50px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.divisore h1 {
  width: 100%;
  text-align: center;
  font-size: 5em;
  font-family: "Dancing Script";
  color: #364731;
  display: flex;
  flex-direction: column;
}

.divisore .cards {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5vw;
}

.divisore .card {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  border-radius: 15px;
  flex-direction: column;
  color: #364731;
  max-width: 20vw;
  height: 45vh;
  transition: 0.3s linear;
  cursor: default;
  user-select: none;
}

.divisore .card:hover {
  transform: translateY(-3%);
  transition: 0.3s linear;
}

.divisore .card p {
  font-size: 1.2rem;
  text-align: center;
  font-weight: 300;
}

.divisore .card h2 {
  font-size: 2rem;
  margin-bottom: 0px;
}

.divisore #centrale {
  transform: translateY(-15%);
}

.divisore #centrale:hover {
  transform: translateY(-18%);
  transition: 0.1s linear;
}

.divisore .buttonSection {
  margin-top: 2%;
  width: 100%;
  text-align: center;
}

.divisore .buttonSection button:hover {
  background-color: #364731 !important;
  color: rgb(255, 243, 226) !important;
  transition: 0.2s linear;
}

.tdbSection {
  margin-top: 10%;
  height: 90vh;
  width: 100%;
  display: flex;
}

.tdbSection .fotoSlideSection {
  width: 55%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(-10%);
}

.tdbSection .fotoSlideSection img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.2s linear;
}

.tdbSection .features {
  width: 45%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.tdbSection .features h1 {
  font-family: "Bodoni Moda";
  text-transform: uppercase;
  font-weight: 300;
  font-size: 2.5rem;
  margin: 0px;
}

.tdbSection .features h2 {
  font-weight: 300;
  margin: 0px;
}

.tdbSection .features .elements {
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  width: 60%;
  align-items: center;
  justify-content: space-between;
}

.tdbSection .features .element {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 300;
  border-bottom: 1px solid #8c8c8c6f;
  padding: 20px 0px;
}

.tdbSection .features .element:hover {
  border-bottom: 2px solid #8c8c8c6f;
  cursor: pointer;
}

.tdbSection .features .buttonSection {
  margin-top: 15%;
}

.luoghi {
  margin-top: 5%;
  display: flex;
  width: 99.5vw;
  transform: translateX(-50px);
  height: 120vh;
  background-color: rgb(255, 243, 226);
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: center;
  align-items: center;
}

.luoghi h1 {
  font-family: "Dancing Script";
  font-size: 3.5em;
  color: #364731;
  margin-bottom: 0px;
  width: 95%;
  margin-left: 10%;
}

.luoghi .lista {
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

// phone view media query
@media screen and (max-width: 900px){
  .vetrina{
    background-position: center;
    height: 70vh !important;
  }

  .toolbar {
    display: none !important;
  }

  .mobile-toolbar{
    display: flex !important;
  }

  .mobile-menu{
    display: flex !important;
  }

  .logoSpan h1{
    font-size: 2.5rem !important;
  }

  .pageContainer{
    padding: 0px 25px !important;
  }

  .subtitleSection h2{
    font-size: 2rem !important;
    text-align: center ;
  }

  .subtitleSection p{
    width: 100% !important;
    font-size: 1.1rem !important;
  }

  .struttura{
    margin: 10% 0px !important;
    flex-direction: column-reverse !important;
  }

  .strutture-right-section img{
    width: 100vw !important;
  }

  .strutture-left-section h1{
    font-size: 2rem !important;
    text-align: center ;
    width: 100% !important;
  }

  .strutture-left-section p {
    width: 100% !important;
    text-align: center !important;
    font-size: 1.1rem !important;
    margin: 0px !important;
  }

  .strutture-left-section .buttonSection{
    width: 100% !important;
    text-align: center !important;
  }

  .tbvCards{
    flex-direction: column !important;
    height: 100vh !important;
  }

  .tbvCards .card{
    width: 100% !important;
    height: 33% !important;
  }

  .tbvCards .card .descrizione h1 {
    font-size: 1.5rem !important;
  }

  .divisore {
    width: 110vw !important;
    margin-top: 10% !important;
    height: fit-content !important;
    padding: 10% 0px;
  }

  .divisore h1 {
    font-size: 3rem !important;
    width: 80% !important;
    margin: 0px !important;;
  }

  .divisore .cards{
    flex-direction: column !important;
    gap: 7vh !important;
  }

  .divisore #centrale{
    transform: translateY(0%) !important;
  }

  .divisore #centrale:hover{
    transform: translateY(0%) !important;
  }

  .divisore .cards .card{
    max-width: 75% !important;
    height: fit-content !important;
    padding: 0px !important;
    gap: 10px !important;

    h2,p{
      margin: 0px !important;
    }

    .icon{
      font-size: 4rem !important;
    }
  }

  .tdbSection{
    flex-direction: column !important;
    gap: 20px;
    height: fit-content !important;
  }

  .tdbSection .fotoSlideSection{
    width: fit-content !important;
  }

  .tdbSection .fotoSlideSection img{
    width: 110vw !important;
    height: 300px !important;
  }

  .tdbSection .features{
    width: 100% !important;
    gap: 10px;

    h1{
      font-size: 2rem !important;
      text-align: center;
      display: none;
    }

    h2{
      font-size: 1.2rem !important;
      text-align: center;
    }

    .elements{
      margin: 0px !important;

      .element{
        font-size: 1.1rem !important;
      }
    }
  }

  .tdbSection .features .buttonSection {
    margin-top: 5% !important;
  }

  .luoghi{
    margin-top: 15% !important;
    padding: 5vh 0px !important;
    width: 110vw !important;
    height: fit-content !important;
    gap: 30px !important;
  }

  .luoghi h1{
    font-size: 2.5rem !important;
    width: 90% !important;
    text-align: center !important;
    margin: 0px !important  ;
  }

  .luoghi .lista{
    height: fit-content !important;
  }
}
.footer {
  width: 100vw;
  height: 25vh;
  background-color: #364731;
  color: white;
  display: flex;
}

.footer .str {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.footer .header {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom: 2px solid white;
  padding: 7px 0px;
}

.footer .logo {
  width: 170px;
  object-fit: contain;
}

.footer .header p {
  margin: 0px;
  font-weight: 200;
}

.footer .header h3 {
  width: 100%;
  font-size: 1.4em;
  text-align: center;
  margin: 0px 0px;
}

.footer .str span {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 5px 0px;
  font-weight: 300;
}

.footer .icons {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  width: 15%;
}

.footer a {
  text-decoration: none;
  color: white;
}

@media screen and (max-width: 900px){
  .footer{
    height: fit-content !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5vh 0px;
  }

  .footer .str{
    width: 60%;
  }

  .footer .str p{
    text-align: center;
  }
}

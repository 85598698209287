.dialog-container {
  width: 80vw;
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Lexend";

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60vh;
  }

  .loader {
    width: 48px;
    height: 48px;
    border: 5px solid #fff;
    border-bottom-color: #364731;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #717171;
    cursor: pointer;
  }

  .dialog-content {
    padding: 0px 40px 10px 40px;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

    .header {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h1 {
        margin: 0px;
        font-family: "Bodoni Moda";
        font-weight: 400;
        text-transform: uppercase;
        font-size: 3rem;
      }

      p {
        font-size: 1.2rem;
        font-weight: 300;
        font-family: "Lexend";
        margin: 0px;
        width: 50%;
        text-align: center;
      }
    }

    .camera-info {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      gap: 50px;

      .imgs-section {
        width: 65%;
        position: relative;
        img {
          width: 100%;
          height: 60vh;
          object-fit: cover;
        }

        .arrow-left,
        .arrow-right {
          background-color: #0000007a;
          padding: 10px;
          color: white;
          border-radius: 50%;
          cursor: pointer;
        }

        .arrow-left {
          position: absolute;
          top: 50%;
          left: 20px;
        }

        .arrow-right {
          position: absolute;
          top: 50%;
          right: 20px;
        }
      }

      .chips-section {
        width: 35%;
        gap: 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .chip {
          font-size: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          background-color: #ddd;
          margin: 0px;
          padding: 5px 10px;
          border-radius: 33px;
          height: 20px;

          .chip-icon {
            margin-top: 4px;
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .dialog-container {
    width: fit-content !important;
    height: fit-content !important;
  }

  .dialog-content {
    padding: 50px 0px !important;
    height: 100% !important;
    .header {
      h1 {
        font-size: 2.5rem !important;
      }
      p {
        font-size: 1.1rem !important;
        width: 90% !important;
      }
    }

    .camera-info {
      flex-direction: column !important;
      gap: 20px !important;

      .imgs-section {
        width: fit-content !important;

        img {
          width: calc(100vw - 10px) !important;
        }
      }

      .chips-section {
        width: 90% !important;
        justify-content: center !important;

        .chip {
          font-size: 0.9rem !important;
        }
      }
    }
  }

  .css-2rbg70-MuiPaper-root-MuiDialog-paper,
  .css-hppdow {
    margin: 0px !important;
    max-height: 100vh !important;
  }
}
